.btn-outline,
.sAbout__btn,
.sAdvantages__btn {
  fill: #0098db;
}

.btn-outline:hover,
.sAbout__btn:hover,
.sAdvantages__btn:hover {
  fill: #fff;
}

.form-wrap__input.error {
  border: 1px solid #ef3124;
}

.fast-deal-amount-currency-error {
  position: absolute;
  top: 5.6em;
  width: 200%;
  color: #ef3124;
}

.custom-input__lab.border-danger {
  border: 1px solid;
}

.canvas-wrap {
  overflow: hidden;
}

.canvas-wrap div canvas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -999;
}

.headerBlock .swiper-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  overflow: hidden;
}

.headerBlock__slide:not(:first-child) img {
  margin-left: 0.6em;
}

.modal-form {
  position: fixed !important;
  display: block;
  visibility: hidden;
  z-index: 1010;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  outline: none;
  background-color: white;
  transition: all 0.2s;
  opacity: 0;
}

.modal-form.open {
  display: block;
  visibility: visible;
  opacity: 1;
}

.modal-form-close-btn {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  font-size: 2em;
  font-weight: 100;
  cursor: pointer;
}

.sAbout img:not(.icon) {
  top: -2.5rem;
}

.sAbout__btn svg {
  width: 1.1em;
  height: 1.1em;
  margin-left: 0.8125rem;
  margin-top: 0.7rem;
}

.sAdvantages__btn svg {
  margin-top: -0.5rem;
}

.sBlog__btn svg {
  width: 1.1em;
  height: 1.1em;
  margin-left: 0.9375rem;
  margin-top: 0.6rem;
  fill: #fff;
}

.sWork__label {
  transition: 0.2s ease;
}

.sWork__label:focus {
  outline: none;
}

.toggle-menu-mobile {
  cursor: pointer;
}

.top-nav__logo span {
  min-width: 7em;
}

.icon-chevron-down {
  width: 0.9em !important;
  fill: #a8b5c6;
}

.icon-chevron-down:hover {
  fill: white;
}

.sAdvantages--phone .sAdvantages__btn .icon-telegram {
  margin-top: 0.2rem;
}

.sAdvantages__btn.phone {
  padding-left: 1.5em;
}

.sAbout--phone .sAbout__btn .icon-telegram {
  margin-top: 0;
}

.sPeople__col {
  cursor: pointer;
}

.sPeople__col:hover {
  transform: scale(1.01);
}

.sPeople__link {
  color: #0098db;
}

.sWork__label {
  cursor: pointer;
}

.headerBlock__link-btn {
  cursor: pointer;
}

.headerBlock__group-link {
  cursor: pointer;
}

.btn-warning.disabled {
  cursor: not-allowed;
}

.home-select {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  height: 2.8em;
  border-radius: 0.2em;
}

.home-select span {
  color: white !important;
}

.home-select .current,
.home-select .options {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0.2em;
  overflow: hidden;
}

.home-select .current {
  z-index: 2;
}

.home-select .current.open {
  background-color: white;
  border-color: #dadee3;
  border-radius: 0.2em 0.2em 0 0;
}

.home-select .current.open span {
  color: black !important;
  font-size: 1em !important;
}

.home-select.fixed .current span {
  color: black !important;
  font-size: 1em !important;
}

.home-select .options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  background-color: white;
  border-top: 0.05em solid #dadee3;
  z-index: 1;
  box-shadow: 0 2px 2px 0 rgba(1, 1, 1, 0.1);
  visibility: hidden;
}

.home-select .options span {
  color: black !important;
  font-size: 1em !important;
}

.home-select .options.open {
  top: 2.8em;
  border-radius: 0 0 0.2em 0.2em;
  visibility: visible;
}

.home-select .option {
  width: 100%;
  height: 1.8em;
}

.home-select .option:not(:first-child) {
  border-top: 1px solid #dadee3;
}

.home-select .arrow {
  position: absolute;
  display: flex;
  align-items: center;
  margin-top: -0.1em;
  right: 0.5em;
  z-index: 2;
}

.home-select.lang-select {
  width: 4.6em;
  border-color: transparent;
}

.home-select.lang-select .arrow {
  display: none;
}

.lang-select-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 0.6em;
}

.lang-select-option span {
  margin-left: 0.4em;
  font-size: 1rem;
}

.home-select.rate-select {
  width: 100% !important;
  border: 0.1em solid #3c4c62;
  background-color: transparent;
  transition: 0.2s ease;
}
/* 
.home-select.fixed.rate-select {
  border-color: #f3f6fa;
  background-color: #f3f6fa;
}

.home-select.open.rate-select {
  border-color: transparent;
} */

.home-select.rate-select .options.open {
  top: 2.6em;
}

.region-select {
  width: 10rem !important;
}

.top-hover {
  color: #f3f6fa;
}

.top-hover:hover * {
  color: black;
}

.top-hover.active * {
  color: black;
}

.top-hover.fixed {
  border-color: black;
}

.top-hover.fixed * {
  color: black;
}

.home-balance {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.home-balance .column {
  display: flex;
  flex-direction: column;
  margin-left: 1.1vh;
}

.home-balance .column span {
  font-size: 0.8rem;
}

.menu-mobile__footer .home-balance {
  margin-top: 3vh;
}

.top-nav.fixed .home-balance .column span {
  color: black;
}

@media screen and (max-width: 992px) {
  .region-select {
    display: none !important;
  }
}
