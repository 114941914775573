@import './vars.scss';
@import './mixin';
@import '../scss/bourbon/app/assets/stylesheets/bourbon';

.root {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
}

body,
html {
  font-family: Euclid Circular B, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol, Noto Color Emoji !important;

  scrollbar-width: none;

  & * {
    scrollbar-width: none;
  }
}

html.personal-page,
body.personal-page {
  @media (max-width: 1440px) {
    font-size: 13px !important;
  }
}

.MuiTypography-body1,
.MuiTableCell-root {
  font-family: Euclid Circular B, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol, Noto Color Emoji !important;
}

.xl-tablet-hidden {
  @media (max-width: 1024px) {
    display: none !important;
  }
}

.tablet-hidden {
  @media (max-width: 834px) {
    display: none !important;
  }
}

.tablet-show {
  @media (min-width: 834px) {
    display: none !important;
  }
}

.mobile-hidden {
  @media (max-width: 576px) {
    display: none !important;
  }
}

.mobile-show {
  @media (min-width: 576px) {
    display: none !important;
  }
}

.bid-head,
.deals-head,
.p2p-head {
  flex-wrap: wrap;
  gap: rem(10);
  justify-content: space-between;
}

.bid-head {
  .toggles {
    display: flex;
    gap: 16px;
    align-items: center;
    padding-bottom: 1.2rem;

    @media (max-width: 930px) {
      width: 100%;
      justify-content: center;
    }

    @media (max-width: 576px) {
      * {
        font-size: 12px !important;
        padding-bottom: unset;
      }
    }
  }
}

.wallet-head,
.user-profile-head,
.add-page-head {
  margin-bottom: rem(12);

  @media (max-width: 834px) {
    margin-left: rem(16);
    margin-right: rem(16);
  }

  @media (min-width: 834px) {
    margin-right: rem(40);
    margin-left: rem(40);
  }
}

.bids-pagination {
  margin-bottom: 0;
}

.user-profile-box__link {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;

  &:hover,
  &.active {
    text-decoration: none;
  }
}

.TopBar {
  display: flex;
  justify-content: space-between;
  position: relative;
  max-height: 3.5rem;

  @media (max-width: 834px) {
    // margin-top: 0 !important;
    // margin-left: -15px;
    // margin-right: -15px;
    // padding-left: 10px;
    // padding-right: 10px;

    .selected {
      padding-right: 0 !important;
    }

    .userBalance {
      order: -1;
      margin-right: auto;

      [viewBox='0 0 452 452'] {
        display: none;
      }
    }
  }

  @media (max-width: 937px) {
    .userBalance {
      width: 18vh;
    }
  }
}

.toggleMenu {
  $h: 2px;
  display: none;
  color: $silverGray;
  cursor: pointer;

  @media (max-width: 833.98px) {
    display: block;
    position: relative;
  }

  width: 48px;
  height: 48px;

  span:after,
  span:before {
    content: '';
    position: absolute;
    left: 0;
  }

  span {
    @include a-cent(y);
  }

  span:before {
    top: $h * 4;
  }

  span:after {
    bottom: $h * 4;
  }

  span {
    position: relative;
    display: block;
    left: 3px;
  }

  span,
  span:after,
  span:before {
    // width: 32px;
    width: 22px;
    height: $h;
    background-color: currentColor;
    transition: all 0.3s;
    backface-visibility: hidden;
    border-radius: 6px;
  }

  // &.on {
  // 	// opacity: 0;
  // }

  &.on span {
    background-color: transparent;
  }

  &.on span:after,
  &.on span:before {
    top: 0;
    bottom: 0;
    transform-origin: 50% 50%;
  }

  &.on span:before {
    transform: rotate(45deg);
  }

  &.on span:after {
    transform: rotate(-45deg);
  }
}

@media (max-width: 833.98px) {
  .PageTitle,
  .controlWrap,
  .wrap-tablet {
    margin-top: rem(20) !important;
  }

  .WalletName {
    padding-left: 0 !important;
  }
}

@media (max-width: 430px) {
  .WalletBalance {
    display: block;
    width: 100%;
    order: 2;
    line-height: 1;

    svg {
      position: relative;
      top: rem(-5);
    }
  }
}

.search-component-container {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  padding: 0 1rem 0 1rem;
  margin-right: 1.5rem;
  align-items: center;
  border-radius: 100px;
  background-color: #ffffff;
  color: #8b959e;

  &.mobile {
    padding: 0 1rem 0 1rem;

    .search-results-container {
      top: 65px;
    }
  }

  input:placeholder-shown {
    text-overflow: ellipsis;
  }

  .hint {
    white-space: nowrap;
  }

  .search-results-container {
    position: absolute;
    top: 90px;
    width: 100%;
    right: 0;
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 2px 3px 32px #a8b8c852;
    z-index: 99;

    &::before {
      width: 20px;
      height: 20px;
      content: '';
      position: absolute;
      top: -10px;
      transform: rotate(45deg);
      left: 50%;
      display: block;
      background-color: #ffffff;
    }

    .search-result {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      cursor: pointer;
      text-decoration: unset;
      color: #8b959e;

      &-link {
        text-decoration: underline;
        color: #0098db;
      }

      svg {
        position: absolute;
        right: 20px;
      }
    }
  }
}

.mobile-user-container {
  margin-left: 1rem;
}

.mobileProfile {
  .tablet-hidden {
    display: flex !important;
  }
}

.MobileMenu {
  height: calc(100vh - 70px);
  overflow-y: hidden;
  overflow-x: hidden;

  @media (min-width: 834px) {
    display: none !important;
  }

  @include mt;
  transform: translateY(-100%);

  &.on {
    transform: translateY(70px);
  }

  top: 0;
  position: fixed;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 200;

  &__head {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e8e8e8;
    justify-content: space-between;
    padding-right: 15px;

    > * {
      padding: 10px 25px !important;
      // padding-right: 20px;
    }
  }

  &__body {
    padding-left: 5px;
    padding-right: 10px;
    padding-bottom: 10px;
    overflow-y: auto;
    height: calc(100vh - 90px - 60px);
  }

  .popover {
    // position: static;
    width: 100%;
  }
}

.user-balanace-select-container {
  flex-grow: 1;
}

.FilterBox {
  margin-top: 0 !important;
  margin-bottom: rem(16);
  padding: rem(40) !important;
  min-height: rem(90);
  font-size: rem(18);
  flex-wrap: nowrap;

  // align-items: center;
  * {
    font-size: rem(18);
  }

  .MuiSvgIcon-root {
    font-size: rem(30);
    margin-bottom: 0;
  }

  .PrivateSwitchBase-root-32 {
    padding: rem(5);
    margin-right: rem(10);
  }

  .MuiFormControlLabel-root {
    margin-bottom: 0;
  }

  &__btn {
    // width: auto;
    @media (min-width: 1281px) {
    }

    width: rem(216);
  }

  &__radio-group_mobile {
    display: none;
  }

  &-deals-page {
    margin-bottom: 0px;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.FilterBox-wrapper {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: rem(24);

  @media (max-width: 833.98px) {
    width: 100%;
  }
}

.MuiTable-root {
  display: table;
}

.deals-cards {
  display: grid !important;

  .select-icon-wrap {
    // position: relative;
    position: static !important;
    display: inline-block;
    transform: translateY(0%) !important;
    margin-left: -1.8rem !important;
    margin-right: -1rem !important;
  }

  @media (min-width: 834px) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: rem(30px);
    grid-row-gap: rem(30px);
  }

  grid-column-gap: rem(25);
  grid-row-gap: rem(25);

  @media (min-width: 1281px) {
    grid-template-columns: repeat(3, 1fr);
  }

  > .item {
    margin: 0;
    width: 100%;
  }
}

* {
  box-sizing: border-box;
}

@media (max-width: 479px) {
  .FilterBox {
    padding: rem(16) !important;

    &__reset-filters-btn,
    &__create-bid-btn {
      width: 100%;
    }

    &__radio-group {
      display: none;

      &_mobile {
        display: flex;
        flex-basis: 100%;
      }

      &-btn {
        flex: 1 1 0px;
        border-width: rem(1.5);
        height: rem(42) !important;

        &:nth-child(1) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        &:nth-child(2) {
          border-radius: 0;
        }

        &:nth-child(3) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        &_inactive {
          border-color: #f3f4f5 !important;
        }

        &_without-right-border {
          border-right-color: white !important;
        }
      }
    }

    &-wrapper {
      gap: rem(16);
    }
  }

  .controlWrap {
    margin-top: 0.5rem !important;
  }
}

@media (max-width: 576px) {
  .controlWrap {
    padding-bottom: 1.2rem;
  }
}

.FilterBox-deals-page {
  .popover {
    max-height: 50vh;
  }
}

.MuiFormControlLabel-root {
  margin-left: 0 !important;
}

body.fixedd {
  overflow: hidden;
}

.filter-p2p {
  margin-bottom: rem(30) !important;

  .popover {
    @media (max-width: 833.98px) {
      max-height: calc(100vh - 36rem);
    }

    max-height: calc(100vh - 22rem);
  }

  // display: none !important;
  @media (max-width: 833.98px) {
    > * {
      margin-left: 0 !important;
      margin-right: 0 !important;
      width: 100%;
      padding: 0;
      padding-bottom: 1rem;
    }
  }
}

.bid-item {
  width: 100%;
  height: auto !important;
  box-shadow: 2px 3px 32px rgba(168, 184, 200, 0.32);
  border: 1px solid #c2c2c2;
  padding: rem(40) !important;
  min-height: auto !important;

  .input-block {
    width: 100%;
  }

  .autorequisites-switch {
    position: absolute;
    right: 0px;
  }

  @media (max-width: 900px) {
    padding: rem(16) !important;
  }

  &_disabled {
    box-shadow: none;
  }
}

.bid-row {
  display: flex;
  flex-direction: column;
  gap: rem(24);
  padding: rem(24);
  background-color: white;
}

.bids-pagination-container {
  margin-top: 2px !important;
  width: 100%;
  background-color: white;
  padding: rem(24);
  border-bottom-left-radius: 0.6vh;
  border-bottom-right-radius: 0.6vh;
}

.card-currency__icon-container {
  width: rem(40);
  height: rem(40);
}

.bid-item {
  flex-basis: 100%;

  &__currency-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: rem(40);
    height: rem(40);
  }
}

.fw-500 {
  font-weight: 500;
}

.order-aside {
  height: 100% !important;
  width: rem(458) !important;
  margin-left: 30px;
  max-width: 33%;
  padding-bottom: 0;
  margin-bottom: 30px;

  .h6 {
    font-weight: normal;
    margin-bottom: 0;
  }

  .item {
    &:last-child {
      border: none;
    }

    @media (max-width: 833.98px) {
      padding: 1rem 0;
    }
  }

  @media (max-width: 833.98px) {
  }

  @media (max-width: 991.98px) {
    margin-left: 0;
    max-width: 100%;
    width: 100% !important;
    order: -1;
    margin-bottom: 30px;
  }

  &__items {
    padding-bottom: 0;

    @media (max-width: 833.98px) {
      padding-top: 0rem;
    }
  }

  @media (min-width: 576px) and (max-width: 991.98px) {
    &__items {
      flex-direction: row;
    }

    .item {
      padding-top: 0;
      border: none;
      padding-left: 0;
      padding-right: 35px;
    }
  }
}

.bid-page {
  .page-bid-form {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
    gap: 40px;

    .row-block {
      margin-top: unset;
    }

    & > div {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    @media (max-width: 576px) {
      grid-template-columns: 1fr !important;
      gap: 16px;
    }
  }

  .bid-rates-table-desktop {
    display: block;
  }

  .autorequisites-switch {
    position: absolute;
    right: 0px;
    top: 0px;
  }

  .bid-rates-table-mobile {
    display: none;
  }

  .MuiTableCell-body {
    white-space: pre;
  }

  .order-main {
    box-shadow: 2px 3px 32px 0px #a8b8c852;
  }

  th > svg {
    width: 30px;
  }

  .page-bid-actions {
    padding-top: 0px;
    justify-content: flex-end;
  }

  @media (max-width: 834px) {
    .order-main {
      margin-bottom: 28px;
    }

    .page-bid-actions .btn-form {
      max-width: unset;
    }

    .bid-rates-table-desktop {
      display: none;
    }

    .bid-rates-table-mobile {
      display: block;
    }
  }
}

.order-main {
  flex-grow: 1;

  .btn-form {
    width: 100%;
    max-width: rem(240);
  }

  @media (max-width: 833.98px) {
    .row-block {
      display: block !important;
    }

    [width='25%'] {
      margin-bottom: 0.5rem;
      width: 100% !important;
    }
  }

  .tabs-control {
    text-align: center;

    @media (max-width: 833.98px) {
      // flex-direction: column;
      .outlineFilled {
        margin-top: 0;
        margin-bottom: 0 !important;
        height: rem(52) !important;
        border-radius: 0 !important;
      }
    }
  }
}

.controlWrap {
  display: flex;
  align-items: flex-end;
  gap: 8px;
  flex-direction: column;
  height: 100%;
  margin-right: 0rem;
  padding-bottom: 16px;

  &__element {
    display: flex;
    align-items: center;
  }

  &__text {
    margin-right: 1.5em;
  }

  @media (min-width: 834px) {
    padding-top: 0.4em;
  }

  label {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 833.98px) {
  .controlWrap > label {
    margin-left: 0 !important;
  }

  .PageTitle {
    font-size: 20px !important;
    padding-bottom: 1.2rem !important;
  }
}

.coll {
  flex-grow: 1;

  @media (max-width: 833.98px) {
    .row-block {
      display: block;
    }

    [width='33%'] {
      max-width: 100% !important;
      margin-bottom: 1rem;
    }
  }
}

.collapce-text {
  @include ellipsis;
  position: relative;
  top: rem(4);
  max-width: calc(100% - 50px);

  span {
    @include ellipsis;
  }
}

@media (max-width: 833.98px) {
}

.select-currency {
  width: rem(160);
}

.last-operations {
  @media (max-width: 833.98px) {
    .btn-form {
      margin-top: 1rem;
    }

    > div {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .mob-w100 {
    @media (min-width: 834px) {
      order: 1;
    }
  }

  @media (max-width: 833.98px) {
    .mob-w100 {
      margin-bottom: 0.5rem;
    }

    &__footer {
      display: block;
    }
  }

  .btn-form {
    @media (min-width: 834px) {
      min-width: rem(270);
      margin-right: 30px;
    }
  }

  @media (max-width: 833.98px) {
    .row-block {
      display: block;
    }

    [width='33%'] {
      margin-bottom: 0.5rem;
      width: 100% !important;
    }
  }
}

@media (max-width: 833.98px) {
  .mob-w50 {
    width: 50% !important;
  }

  .mob-w100 {
    width: 100% !important;
  }
}

.react-datetime-picker__wrapper {
  svg {
    stroke: #0078d7;
  }
}

.order-2 {
  order: 2;

  @media (max-width: 820px) {
    [height='3.2vh'] {
      display: none;
    }
  }
}

.filter-top {
  @media (min-width: 834px) {
    .order-2 {
      margin-left: auto;
    }
  }

  .btn-form {
    width: rem(180px) !important;
    margin-left: -1.125rem;
    order: 2;
  }

  @media (max-width: 834px) {
    .mob-w50 {
      order: 1;
    }

    .react-datetime-picker {
      width: 100%;
    }

    .mob-w100 {
      > div {
        margin-left: 0;
      }

      [width='10rem'] {
        width: 100%;
      }
    }
  }
}

.MobileMenuToggle {
  .MenuItemsList {
    // display: block;
    flex-wrap: wrap;
  }

  .MenuItem {
    // display: block;
    display: flex;
    width: 100%;
    position: relative;
    border-bottom: 1px solid #e8e8e8;
    padding-left: rem(62);

    img {
      // position: absolute;
      // l
    }
  }

  .icon-wrap {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
  }
}

.menuFooter {
  @media (min-width: 833.69px) {
    display: none;
  }

  @media (max-width: 833.69px) {
    display: block;
  }

  .MenuItemsList {
    justify-content: space-around;
    gap: 24px;
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;

    @media (max-width: 390px) {
      gap: 8px;
    }
  }

  position: fixed;
  width: 100vw;
  left: 0;
  bottom: 0;
  background-color: #ffffff;
  z-index: 100;

  .MenuItem {
    text-align: center;
    display: flex;
    justify-content: center;
    width: auto;
    padding: 26px 5px;
    flex-grow: 1;

    &.active {
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08), inset 0px 3px 0px #00b3fb;
    }
  }

  img {
    @include mt;
    filter: saturate(0);

    &:hover {
      filter: saturate(1);
    }
  }
}

.pageTitle {
  font-weight: 500;
  white-space: normal !important;
}

.referrals-main {
  @media (min-width: 1100px) {
    max-width: calc(100% - 33%) !important;
    flex-grow: 1;
    order: 0 !important;
  }
}

.referrals-aside {
  height: 100% !important;

  @media (min-width: 1100px) {
    width: rem(458) !important;
    margin-left: 30px;
    max-width: 33% !important;
  }

  width: 100% !important;
  padding-bottom: 0;
}

.mb-30 {
  margin-bottom: rem(30);
}

.promocode-block {
  @media (max-width: 833.98px) {
    .ControlsWrap {
      flex-wrap: wrap;

      > div {
        &:nth-child(1) {
          width: calc(55% - 1rem);
        }

        width: calc(45% - 1rem);
        min-width: 1px;
        margin-bottom: 1rem;
      }
    }

    .btn-box {
      width: 100% !important;
      margin-left: 0 !important;

      [height='3.75rem'] {
        width: 100%;
      }
    }
  }

  @media (max-width: 833.98px) {
    &:nth-child(1) {
      margin-bottom: 20px;
    }
  }

  @media (min-width: 834px) {
    max-width: calc(45% - 15px);
    width: 100%;
    flex: 0 0 calc(45% - 15px);
    min-height: rem(315);

    &:nth-child(1) {
      margin-bottom: 0;
      max-width: calc(55% - 15px);
      flex: 0 0 calc(55% - 15px);
      margin-right: 30px !important;
    }
  }

  width: 100% !important;
  min-width: 1px !important;
}

.promocode-main {
  > div {
    align-items: inherit !important;
    flex-wrap: wrap;
  }

  .crypto-select {
    [height='3.75rem'] {
      padding-left: 0.6rem;

      &.selected {
        // padding-left: 0;
      }

      &.active {
        padding-left: 0;
      }
    }
  }
}

[height='3.8em'] {
  img {
    margin-left: -0.5rem !important;
  }
}

.minimized .menu-root-inner {
  width: 70px;
}

.menu-root-inner {
  background-color: #ffffff;
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
  width: inherit;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1000;
}

.arrow-text {
  margin-left: 1em;
}

.filter-p2p {
}

button {
  outline: none !important;
}

.MuiTableCell-root {
  > div {
    position: relative;
  }

  .select-icon-wrap {
    left: rem(0) !important;
    margin-top: rem(-2) !important;

    @media (min-width: 1100px) {
    }
  }
}

.maintenance-block {
  svg {
    width: rem(30) !important;
  }

  form {
    flex-grow: 1;

    input {
      width: 100%;
      margin-left: 0.5rem !important;
    }
  }
}

.add-main {
  margin-bottom: 30px;

  svg {
    width: rem(20);
  }
}

.adaptive-main {
  @media (max-width: 1280px) {
    flex-direction: column;
  }
}

.wrap-tablet {
  margin-bottom: 30px;
}

.pageSetting {
  @media (max-width: 833.98px) {
    .tabs-control {
      display: block;
      // padding: 0 2.1rem;
      margin-left: -2.1rem;
      margin-right: -2.1rem;

      > div {
        width: 100% !important;
        margin: 0 !important;
      }
    }
  }

  .row-block {
    @media (max-width: 833.98px) {
      display: block;

      [width='100%'] {
        margin-top: rem(5);
      }
    }

    .inputTitle {
      display: block;
      max-width: rem(250);
      align-self: center;
      margin-bottom: 2rem;

      @media (max-width: 833.98px) {
        margin-bottom: 1rem;
      }

      width: 100%;
      min-width: 1px !important;

      + div {
        flex-grow: 1;
      }
    }
  }

  > span,
  [width='24.625rem'] {
    max-width: rem(250);
    width: 100%;
    min-width: 1px !important;
  }
}

[height='65vh'] {
  form {
    flex-grow: 1;

    input {
      width: 100%;
      margin-left: 0.5rem !important;
    }
  }
}

.deal-aside {
}

.ReferralBonuses {
  flex-wrap: wrap;
}

.hasCopy {
  max-width: 100%;
}

.btn-form {
  text-align: center;
}

@media (max-width: 834px) {
  .addr-row {
    width: 100%;
  }
}

.addr-wrap {
  // display: inline-block;
  max-width: calc(100% - 82rem);

  span {
    max-width: 100%;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: visible;
    // white-space: normal !important;
    // word-wrap: break-word;
  }
}

.order-row {
  form {
    flex-grow: 1;

    input {
      flex-grow: 1;
      width: 100%;
      margin-left: 0.5rem !important;
    }
  }
}

.right-block {
  .order-aside {
    // margin: 0;
    margin-bottom: 0;
    width: 100% !important;
    max-width: 100% !important;
    margin-left: 0 !important;
  }
}

.right {
  @include mt;
}

.minimized {
  .right {
    position: relative;
    // right: rem(-15);
  }
}

.block-p2p {
  margin-bottom: 30px;
}

.select-icon-wrap {
  img {
    height: 1.2rem !important;
  }
}

.right-block .select-icon-wrap {
  img {
    height: 1.875rem !important;
  }
}

.bid-head .PageTitle {
  // margin-right: rem(30);
  padding-right: rem(30);
}

.tab-g1 {
  @media (min-width: 834px) {
    &:last-child {
      padding-right: 15px;
    }
  }
}

@media (min-width: 834px) {
  .typeF {
    min-width: rem(200);
    max-width: rem(200);
  }

  .tab-g1 {
    flex-grow: 1;

    &.last-el {
      padding-right: 15px;
    }

    .react-datetime-picker {
      display: flex;
      // min-width: rem(250px);
    }
  }
}

.react-datetime-picker__inputGroup__input:invalid {
  background: none !important;
  outline: none !important;
}

.minimized .right {
}

.minimized .arrow-text {
  margin-left: 0rem;
  width: 1px;
}

.minimized .arrow-circle {
  position: relative;
  left: rem(12) !important;

  @media (min-width: 1281px) {
    left: rem(-2) !important;
  }

  @media (min-width: 1440px) {
    left: rem(-12) !important;
  }
}

.hidden-tablet .order-aside {
  margin-bottom: 0;
}

@media (max-width: 833.98px) {
  .order-row {
    // margin-bottom: 30px;
    display: block !important;

    [height='50vh'] {
      min-width: 1px !important;
    }
  }

  .MobileMenu__body {
    [width='3.75rem'] {
      margin-left: 0.3rem !important;
    }
  }

  .MobileMenu__search__wrapper {
    width: 100%;
    padding: 20px;
    background-color: #f1f1f1;
  }

  .border-bottom {
    margin-right: -15px;
    padding-right: 15px;
  }
}

.MobileMenu__body {
  [height='3.75rem'] {
    // border-bottom: 1px solid #e8e8e8 !important;
    // margin-right: rem(-15);
  }
}

.mobileProfile {
  border-bottom: 1px solid #e8e8e8 !important;
  margin-right: rem(-15);
  padding-right: rem(15);
}

.react-datetime-picker__inputGroup {
  .react-datetime-picker__inputGroup__day,
  .react-datetime-picker__inputGroup__month {
    min-width: rem(40) !important;
    text-align: center;
    // font-size: rem(24) !important;
  }

  .react-datetime-picker__inputGroup__year {
    width: rem(60) !important;
    text-align: center;
    // font-size: rem(24) !important;
  }
}

@media (max-width: 833.98px) {
  .MuiDialog-paperWidthFalse,
  .MuiDialog-paperScrollPaper {
    max-width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-bottom: 1rem !important;

    [width='14.625rem'] {
      width: 150px !important;
    }
  }

  [width='293px'],
  [width='22vh'] {
    width: 100% !important;
  }

  [width='15vh'] {
    width: calc(50% - 1rem) !important;
    display: inline-flex;
  }

  .btn-wrap-flex {
    justify-content: space-between;
  }

  [width='60vh'] {
    max-width: 100% !important;
    width: 100% !important;
  }

  .fastDeal-row {
    display: block !important;
    margin-left: auto;
    margin-right: auto;
    max-width: rem(370);
  }

  .deal {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .mobile-d-block {
    display: block !important;

    > [color='#808080'] {
      display: block;
    }
  }

  .deal-btn-wrap {
    display: block !important;
    text-align: center !important;

    > div {
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-bottom: 1rem;
    }
  }

  .dealText {
    max-width: 100%;
    width: 100% !important;
  }

  .deal .tabs-control {
    margin-left: 0 !important;
    text-align: center;

    span {
      font-size: rem(14);
    }

    // font-size: 10px;
  }
}

.mobile-d-block {
  > [color='#808080'] {
    margin-right: 5px;
  }
}

.message {
  img {
    max-width: 100%;
    height: auto !important;
    max-height: 10rem;
  }
}

.deal {
}

.MuiSvgIcon-root {
  position: relative;
  top: rem(-2);
}

.react-datetime-picker {
  height: rem(60);
}

.MuiIconButton-label {
  font-size: 1.25rem;
}

.MuiSvgIcon-root {
  font-size: 1.8rem !important;
}

.notificationsItem {
  @media (min-width: 834px) {
    height: 63vh;
  }
}

.react-datetime-picker__wrapper {
  padding-left: rem(10);
}

.react-datetime-picker__inputGroup__leadingZero {
  // position: relative;
  position: absolute;
  top: rem(19);
  margin-left: rem(6);
  // left: rem(5);
}

.react-datetime-picker__inputGroup__input--hasLeadingZero {
  // margin-left: -0.5em;
  // margin-left: 0;
  // padding-left: 0;
  // padding-left: calc(1px + 0.8em);
}

@media (max-width: 833.98px) {
  .FilterBox-head {
    > div {
      width: 100% !important;
      margin-left: 0 !important;
    }

    [width='10rem'],
    .react-datetime-picker {
      width: 100% !important;
    }
  }

  .lang-select-option span {
    display: none;
  }

  .home-select.lang-select {
    width: 2.4rem !important;

    img {
      height: 1.2em !important;
    }
  }

  .top-nav__logo {
    svg {
      margin-right: rem(10) !important;
    }
  }
}

.coll {
  max-width: 100%;
}

.addr-row {
  svg {
    flex: 0 0 auto;
  }
}

.addr-wrap {
  color: #0098db;
  margin-left: 0.5rem;
  display: block;
  flex-grow: 1;

  max-width: 100%;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mainBlock {
  flex-grow: 1;
  height: 100%;
  width: 80%;
  padding: 0 40px;
  max-width: 1092px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 992px) {
    padding-bottom: 9rem;
    padding: 0 24px;
  }

  @media (max-width: 833.69px) {
    padding-bottom: 9rem;
    padding: 0 16px;
  }

  @media (min-width: 1281px) {
    max-width: 1501px;
  }
}

.TopBar .popover {
  [height='3.75rem'] {
    height: auto !important;
    min-height: 3.75rem;
  }
}

.referrals-aside {
  .select-icon-wrap img {
    height: 1.875rem !important;
    flex-shrink: 0;
  }
}

table .MuiSvgIcon-root {
  width: 0.8rem;
  height: 0.8rem;

  @media (max-width: 1240.69px) {
    display: none;
  }
}
