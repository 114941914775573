@import 'src/assets/scss/vars';

%pagination-item__span {
  display: block;
  position: relative;
  top: 1px;
  font-size: 18px;
  color: $neroColor;
  font-weight: 500;
}

.pagination-container {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  list-style-type: none;
  margin: 0 8px;

  .pagination-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    min-width: 40px;
    margin: auto 2px;
    box-sizing: border-box;
    letter-spacing: 0.01071em;
    border-radius: 50%;
    line-height: 1.43;
    text-align: center;

    span {
      color: $silverGray;
      font-size: 16px;
    }

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      background-color: $aliceBlue;
      cursor: pointer;

      span {
        @extend %pagination-item__span;
      }
    }

    &.selected {
      background-color: $aliceBlue;

      span {
        @extend %pagination-item__span;
      }
    }

    &.disabled {
      pointer-events: none;

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}

.pagination-button {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border: 1px solid #00000024;
  border-radius: 4px;
  transition: 0.4s;
  user-select: none;
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 50%;

  .arrow {
    display: block;
    position: relative;
    width: 8px;
    height: 8px;
    border-top: 1px solid $silverGray;
    border-left: 1px solid $silverGray;
    transition: 0.4s;

    &.left {
      transform: rotate(-45deg);
    }

    &.right {
      transform: rotate(135deg);
    }

    &::after {
      content: '';
      display: block;
      width: 1px;
      height: 10px;
      background-color: $silverGray;
      transform: rotate(-45deg) translate(3px, 2px);
      left: 0;
      top: 0;
      transition: 0.4s;
    }
  }

  &:hover {
    background-color: $silverGray;

    .arrow {
      &::after {
        background-color: white;
      }
      border-color: white;
    }

    span {
      color: white;
    }
  }

  &.disabled {
    pointer-events: none;

    &:hover {
      background-color: transparent;
      cursor: default;
    }

    .arrow {
      &::after {
        background-color: #00000036;
      }
      border-color: #00000036;
    }

    span {
      color: #00000036;
    }
  }

  &.mobile {
    .arrow {
      right: -2px;
    }
  }
}
